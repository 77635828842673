.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(60vh - 80px);
}

.image-gallery-thumbnail-inner {
    max-height: 115px;
}

.fullscreen {
    max-height: calc(90vh - 80px);
    .image-gallery-slide .image-gallery-image {
        max-height: calc(90vh - 80px);
    }
}