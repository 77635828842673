.leaflet-container {
    position: relative;
    width: 100%;
    height: 70vh;
    @media only screen and (max-width: 772px) {
        width: 85vw;
    }
}

.leaflet-control-attribution {
    height: 0;
}